@font-face {
    font-family: "FreeSet";
    src: url("../assets/fonts/FreeSetLightC.ttf");   
}

@font-face {
    font-family: "FreeSet";
    src: url("../assets/fonts/FreeSet\ Regular.ttf");   
}

@font-face {
    font-family: "FreeSet-Bold";
    src: url("../assets/fonts/FreeSet\ Bold.ttf");
}

@font-face {
    font-family: "Inter";
    src: url("../assets/fonts/Inter_28pt-SemiBold.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Inter";
    src: url("../assets/fonts/Inter_28pt-Bold.ttf");
    font-weight: 900;
}

@font-face {
    font-family: "Inter";
    src: url("../assets/fonts/Inter_28pt-Regular.ttf");
    font-weight: 400;
}

summary::marker,
summary::-webkit-details-marker {
  display: none;
}

/*Анимация галлереи на странице Карьера*/

@keyframes slider-career-animation-next-slide {
    from {
        transform: translateX(250px);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slider-career-animation-previous-slide {
    from {
        transform: translateX(-250px);
    }
    to {
        transform: translateX(0);
    }
}

.header_adaptiv_search .search-container::before {
    display: none !important;
}

.search_menu {
    width: 700px !important;
}

.search_menu .burger_details img {
    height: 32px;
    width: 32px !important;
}

.search_menu .burger_details > summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c2c2c2;
    padding: 13.5px 0;
}

.slider-career-next-slide {
    animation: slider-career-animation-next-slide .5s;
}

.slider-career-previous-slide {
    animation: slider-career-animation-previous-slide .5s;
}

/*ПОПАП*/

.modal-open {
    display: flex !important;
    z-index: 99999;
}

.modal_with_content {
    display: none;
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
}

.modal-with-content__container {
    position: absolute;
    width: 50vw;
    height: 90vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    overflow-y: auto;
}

body:has(.modal-open) {
    overflow: hidden;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "FreeSet", sans-serif;
}

html {
    scroll-behavior: smooth !important;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
}

button {
    cursor: pointer;
    border: none;
    background: none;
}

.container {
    width: 1400px;
    margin: 0 auto;
}

/*ШАПКА*/

.title_h1_main {
    font-family: "FreeSet", sans-serif;
    color: #fff;
    text-transform: uppercase;
    font-size: 27px;
    margin-bottom: 20px;
}

.header_menu_adaptiv, .running_line_2 {
    display: none;
}

.header_adaptiv_burger {
    z-index: 100000;
}

.header_adaptiv_logo {
    z-index: 100000;
}

header hr {
    display: none;
}

.header_logo {
    display: flex;
    align-items: center;
    border-left: 0.50px solid #c2c2c2;
    padding: 0px 20px;
}

.running_line {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_menu {
    display: inline-flex;
    justify-content: space-between;
    position: relative;
}

.menu_inform_top {
    display: flex;
    border-left: 0.50px solid #c2c2c2;
    border-right: 0.50px solid #c2c2c2;
}

.running_line, .running_line_2 {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
    color: #497af9;
}

.header_number a {
    font-family: "FreeSet-Bold", sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: #161828;
    display: flex;
    transition: 0.3s;
}

.header_number a:hover {
    color: #497af9;
}

.header_menu_inform {
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.menu_inform_bottom ul {
    display: inline-flex;
    width: 100%;
    max-height: 100%;
    height: 100%;
}

.menu_inform_bottom li {
    display: flex;
    width: calc(100% / 6);
    border-left: 0.50px solid #c2c2c2;
    border-top: 0.50px solid #c2c2c2;
    justify-content: space-around;
}

.menu_inform_bottom li * {
    width: auto;
}

.header_link .header_link_item {
    width: 32px;
}

.header_menu_items {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #161828;
    /* padding: 25px 37px; */
    display: flex;
    align-items: center;
}

.header_menu_items:hover {
    background-color: #497AF9;
}

.header_submenu {
    display: none !important;
}

/* .header_menu_items.is_menu .header_submenu {
    display: flex !important;
} */

.about_company:hover .header_submenu {
    display: flex;
}

.submenu_body {
    font-family: "FreeSet-Bold", sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #161828;
}

.test > * {
    height: calc(100% / 2);
}

.header_menu_items.is_menu {
    display: inline-flex;
    align-items: center;
    text-wrap: nowrap;
    transition: 0.3s;
    justify-content: center;
    width: 100%;
}

.header_menu_items.is_menu > img {
    margin-left: 8px;
    height: 6px;
    width: 10px;
}

.header_menu_items.is_menu:hover > img {
    height: auto;
    width: 6px;
}

.header_menu_items:hover {
    color: white;
}

.submenu_body {
    display: none;
    position: absolute;
    width: 100% !important;
    top: 146px;
    left: 0px;
    padding: 60px 60px 70px;
    background: #f3fbfd;
}

.submenu_body_2 {
    display: none;
    position: absolute;
    width: 142px !important;
    top: 146px;
    left: 361px;
    padding: 20px;
    background: #f3fbfd;
}

.submenu:hover .submenu_body {
    /* display: flex;
    flex-wrap: wrap; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    z-index: 1000000000;
}

.submenu:hover .submenu_body_2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
}

.burger_details .submenu_body_title {
    font-weight: normal;
    font-size: 12px;
    transition: 0.3s;
}

.burger_details .submenu_body_title:hover {
    color: #497af9;
}

.close-hover.header_adaptiv_search:hover > .search_top {
    display: none !important;
}

.submenu_body_title {
    font-family: "FreeSet-Bold", sans-serif;
    font-weight: 600;
    font-size: 12px;
    color: #161828;
    margin-bottom: 8px;
    transition: 0.2s;
}

.submenu_body_title_item1:hover {
    color: #497af9;
}

.submenu_body_item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 8px;
}

.submenu_body_item p {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
    color: #282e40;
    transition: 0.2s;
}

.submenu_body_item p:hover {
    color: #497af9;
}

.header_call {
    display: flex;
    flex-direction: column;
}

.header_call > div {
    height: calc(100% / 2);
    border-right: 0.50px solid #c2c2c2;
    display: flex;
    justify-content: center;
    align-items: center;
}

table {
    border-spacing: 0px;
    border-collapse: collapse;
}

.header_link {
    display: flex;
    align-items: center;
    gap: 4px;
    border: 0.50px solid #c2c2c2;
    border-bottom: 0;
    padding: 20px;
}

.header_link_item {
    border-radius: 100%;
    background-color: #f3fbfd;
    padding: 7px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

.header_link_item > svg {
    fill: #497AF9;
}

.header_link_item:hover > svg {
    fill: white;
}

.header_link_item:hover {
    background: #497AF9;
}

.header_inscription {
    display: flex;
    align-items: center;
    padding: 24px 17px;
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
    text-align: center;
    color: #fff;
    background: #497af9;
    border-right: 0.50px solid #c2c2c2;
    text-wrap: nowrap;
    cursor: pointer;
}

/*1 БЛОК*/

.main_block {
    background-image: url("../../public/img/main_block/fon.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 521px;
    width: 100%;
}
/* 
.main_block_service {
    background-image: url("../../public/img/laboratory_block/fon2.png") !important;
    height: auto;
} */

.main_block_contact {
    background-image: url("../../public/img/contact_block/fon.png") !important;
    height: auto;
}

.main_block_equipment {
    background-image: url("../../public/img/equipment_block/fon.png") !important;
    height: auto;
}

.main_block_career {
    background-image: url("../../public/img/career_block/fon.png") !important;
    height: auto;
}

.main_block .container {
    padding: 119px 0 143px;
}

.title {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 42px;
    line-height: 140%;
    text-transform: uppercase;
}

.main_title {
    font-family: "FreeSet-Bold", sans-serif;
    color: #fff;
    padding: 6px 9px;
    margin-bottom: 46px;
    background: #497af9;
    line-height: 200%;
    width: max-content;
    font-weight: bold;
}

.main_title_news_detail {
    width: 100%;
} 

.description {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #161828;
}

.main_description {
    color: #fff;
    margin: 24px 0 48px;
    width: 85%;
}

.button {
    padding: 15px 0;
    width: 277px;
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    transition: 0.3s;
    border: 1px solid rgb(255 0 0 / 0%);
}

.button img {
    transition: 0.3s;
}

.blue_button:hover {
    background: #fff;
    color: #002148;
    border: 1px solid #002148;
}

.blue_button:hover img {
    filter: brightness(0%);
}

.white_button {
    background: #fff;
    color: #002148;
}

.white_button:hover {
    background: #497af9;
    color: #fff;
}

.white_button:hover img {
    filter: brightness(0) invert(1);
}

.expertise_block .white_button:hover, .licenses_block .white_button:hover {
    color: #fff;
    border: 1px solid #fff;
}

.main_button_switches {
    display: flex;
    justify-content: flex-end;
    margin-top: 21px;
}

.main_button {
    padding: 16px 26px;
    background: #fff;
    transition: 0.3s;
}

.main_button_left {
    border-right: 1px solid #497af9;
    z-index: 95;
}

.main_button:hover {
    background: #497af9;
}

.main_button:hover img {
    filter: brightness(0) invert(1);
}

/*2 БЛОК*/

.mini_block {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    z-index: 0;
  }
  
  .mini_block_all_cards {
    display: inline-block;
    animation: marquee 50s linear infinite;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }
  
  .mini_block_card_container {
    display: inline-block;
  }
  
  .mini_block_card {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    padding: 18px 53px 18px 18px;
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
    text-align: center;
    color: #000;
    transition: 0.3s;
  }
  
  .mini_block_card img {
    width: 44px;
    height: 44px;
    transition: 0.3s;
  }
  
  .mini_block_card:hover {
    color: #f3fbfd;
    background: #497af9;
  }
  
  .mini_block_card:hover img {
    filter: brightness(0) invert(1);
  }
  
  @keyframes marquee {
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); }
  }
  

/*3 БЛОК*/

.search_block {
    background: #f3fbfd;
}

.search_block .container {
    padding: 76px 0 49px;
    display: flex;
}

.search_title {
    color: #161828;
}

.search_description {
    color: #282e40;
    margin: 64px 0 20px;
}

.search_description2 {
    margin-top: 20px;
}

.search-container {
    position: relative;
}
  
  .search {
    border: 0.50px solid #c2c2c2;
    border-radius: 10px;
    padding: 18px 15px 18px 50px;
    width: 700px;
    height: 53px;
    background: #fff;
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #c2c2c2;
  }

  select.search {
    appearance: none;
  }
  
  .search-container::before {
    content: "";
    position: absolute;
    left: 15px; /* Размещаем изображение слева от текста */
    top: 26px;
    transform: translateY(-50%); /* Центрируем изображение по вертикали */
    width: 32px; /* Размеры изображения */
    height: 32px;
    background-image: url("../../public/img/search_block/loupe.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .search_top {
      display: none;
      position: absolute;
      top: 72px;
      left: -928px;
      width: 1000px;
      z-index: 10000;
  }

  .search_top > input {
    width: 100%;
    height: 53px;
    padding-left: 50px;
    border: 0.50px solid #c2c2c2;
    background: #f3fbfd;
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #c2c2c2;
  }

  .search_menu .burger_details > summary {
    border-bottom: none;
    cursor: pointer;
  }

  .search_menu .burger_details ul {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .search_menu .burger_details ul a {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: #282e40;
    transition: 0.3s;
  }

  .search_menu .burger_details ul a:hover {
    color: #497af9;
  }

    .search_menu .burger_details[open] > summary > img {
        transform: rotate(90deg);
    }

    .search_menu .burger_details[open] > summary {
        color: #497af9;
    }

  .header_adaptiv_search {
      position: relative;
  }

  .header_adaptiv_search:hover > .search_top {
      display: block;
  }

/*4 БЛОК*/

.fourth_block .container {
    padding: 105px 0 80px;
}

.fourth_title {
    color: #282e40;
    margin-bottom: 74px;
    width: 70%;
}

.fourth-inform {
    display: grid;
    grid-template-columns: 18% calc(82% - 20px);
    gap: 20px;
}

.fourth_button {
    padding: 15px 10px;
}

.fourth_black_block, .fourth_black_block_adaptiv {
    padding: 26px 17px 34px;
    background: #161828;
    height: 221px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.fourth_black_block_adaptiv {
    display: none;
}

.fourth_description {
    color: #fff;
}

.fourth_black_block img, .fourth_black_block_adaptiv img {
    width: 71px;
    height: 9px;
}

.fourth_big_block_top {
    display: flex;
    gap: 20px;
    align-items: flex-end;
}

.subtitle {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-transform: uppercase;
    color: #161828;
}

.fourth_big_block_inform .description {
    color: #282e40;
    margin: 24px 0 48px;
}

.blue_button {
    background: #497af9;
    color: #f3fbfd;
    display: flex;
    justify-content: center;
}

.fourth_button {
    width: 100%;
}

.fourth_big_block_link {
    display: flex;
    justify-content: space-between;
    padding: 43px 101px 41px 13px;
    border-bottom: 1px solid #c2c2c2;
    cursor: pointer;
}

.fourth_big_block_link_left {
    display: flex;
    gap: 21px;
}

.fourth_big_block_link_left img {
    width: 44px;
    height: auto;
}

.fourth_block_mobile_photo {
    display: none;
}

.subtitle_link {
    color: #282e40;
}

.link_arrow {
    width: 17px;
    height: 17px;
}

.drop_down_block > summary::marker {
    display: none;
    content: "";
}

.fourth_big_block_answers {
    border-bottom: 1px solid #c2c2c2;
    padding: 0 0 10px 80px;
}

.fourth_big_block_questions[open] > summary > .fourth_big_block_link {
    border-bottom: none;
}

.fourth_big_block_questions[open] > summary > .fourth_big_block_link > .fourth_big_block_link_left > .subtitle_link {
    color: #497af9;
}

.fourth_big_block_questions[open] > summary > .fourth_big_block_link > .link_arrow_container > img.link_arrow {
    transform: rotate(90deg);
    filter: brightness(0) saturate(100%) invert(40%) sepia(65%) saturate(3106%) hue-rotate(212deg) brightness(102%) contrast(95%);
}

/*5 БЛОК*/

.expertise_block, .licenses_block {
    background-image: url("../../public/img/expertise_block/fon.png");
}

.expertise_block .container {
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.expertise_subtitle {
    color: #f3fbfd;
}

.expertise_title {
    color: #fff;
    text-align: center;
    margin: 64px 0;
}

.text {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
}

.expertise_block .text {
    text-align: center;
    color: #fff;
    margin-bottom: 64px;
}

.expertise_block .white_button {
    color: #282e40;
}

/*6 БЛОК*/

.news_block {
    background: #f3fbfd;
}

.news_block .container {
    padding: 100px 0;
}

.news_title {
    color: #161828;
}

.title_and_button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 65px;
}

.title_and_button .main_button_switches {
    display: none;
}

.button_and_vk {
    display: flex;
    gap: 10px;
}

.news_block .button_vk {
    height: 100%;
}

.news_whole_slider {
    display: flex;
    gap: 20px;
}

.main_slide .news_slide_date {
    margin: 24px 0 16px;
}

.main_slide img {
    width: 700px;
}

.news_slide_img img {
    width: 254px;
}

.news_slide_date {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #161828;
}

.news_all_slides {
    display: flex;
    flex-direction: column;
    gap: 24px;
    transition: 0.5s;
}

.slider_arrow {
    height: 14px;
    width: 27px;
    cursor: pointer;
    margin-left: 16%;
}

.news_slide {
    display: none;
    gap: 24px;
}

.news_slide_inform {
    display: flex;
    flex-direction: column;
}

.show-slide {
    display: flex !important;
}

.adaptiv_news_more, .adaptiv_news_button {
    display: none !important;
}

.news_more {
    display: flex;
    gap: 8px;
    align-items: center;
    margin: 16px 0 69px;
    cursor: pointer;
}

.news_more a {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 0%;
    text-decoration-skip-ink: none;
    color: #497af9;
    transition: 0.3s;
}

.news_more a:hover {
    text-decoration: underline;
}

.news_more img {
    width: 6px;
    height: 10px;
}

/*7 БЛОК*/

.questions_block .container {
    padding: 100px 0;
}

.all_questions {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.questions_block .button_adaptiv {
    display: none;
}

.question {
    padding: 30px 158px 30px 48px;
    border: 1px solid #ecf1ff;
    cursor: pointer;
    transition: 0.3s;
}

.question:hover {
    background: #f3fbfd;
}

.question_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px
}

.question .text {
    color: #282e40;
}
    
.question_plus {
    width: 22px;
    height: 22px;
    transition: 0.3s;
}

.question_activ_block {
    display: none;
}

.question hr {
    margin-bottom: 12px;
}

.question.active {
    background: #f3fbfd;
}

.question.active .question_main {
    margin-bottom: 30px;
}

.container_question_plus {
    display: flex;
    transition: 0.3s;
}

.question.active .container_question_plus {
    transform: rotate(45deg);
    border-radius: 50%;
    background: #497AF9;
    padding: 12px;
}

.question.active .question_plus {
    filter: brightness(0) invert(1);
}

.question.active .question_activ_block {
    display: flex;
    flex-direction: column;
    padding: 0px 110px 0 15px;
}

/*ПОДВАЛ*/

.footer {
    background-image: url("../../public/img/footer/fon.png");
}

.footer .container {
    display: flex;
    justify-content: space-between;
    padding: 55px 0 73px;
}

.footer_block .subtitle {
    color: #fff;
}

.footer_block .description {
    color: #fff;
}

.footer_text {
    display: flex;
    flex-direction: column;
}

.footer_text > details[open] > summary > p {
    font-weight: 600;
    border-bottom: 0.30px solid #f3fbfd;
}

.footer_text_item {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
    color: #ecf1ff;
    cursor: pointer;
    display: inline-block;
}

.footer_text_item_mini {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 16px 8px 8px;
}

.footer_text_item_mini p {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
    color: #f3fbfd;
}

.footer summary {
    list-style: none
}

.footer_inform .subtitle, .footer_services .subtitle {
    margin-bottom: 28px;
}

.subtitle.about_organization {
    margin-bottom: 15px;
}

.footer_inform .footer_text, .footer_services .footer_text {
    gap: 13px;
}

.footer_link {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.footer_contact .subtitle {
    margin-bottom: 11px;
}

.footer_contact_planshet_vers {
    display: none;
}

.footer_phones_links {
    margin-top: 24px;
}

.footer_inscription {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
    color: #c2c2c2;
    margin-bottom: 5px;
}

.footer_fax {
    margin-top: 8px;
}

.footer_phone {
    font-family: "FreeSet-Bold", sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #fff;
}

.footer_channels {
    margin-top: 24px;
    display: flex;
    gap: 24px;
}

.footer_social_media_link {
    display: flex;
    gap: 4px;
    margin-top: 10px;
}

.footer_tg_news .footer_inscription {
    margin-bottom: 10px;
}


.footer_inform .footer_text a {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
    color: #ecf1ff;
}

.footer_link_planshet_vers {
    display: none;
}

/*ПОПАП*/

.modal {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #0000006c;
    width: 0;
    height: 100%;
    z-index: 99999;
    transition: 0.3s;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal.opened{
    width: 100%;
}
.modal-body {
    background: #fff;
    width: 600px;
}

.popup_logo {
    background-image: url("../../public/img/popup/fon.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px 0 20px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}

.popup_inform {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error {
    display: none;
    font-size: 12px;
    color: #F40000;
    margin-top: 0 !important;
    text-align: left;
    width: 100%;
}

.error_show {
    display: flex;
}

.popup_form {
    display: flex;
    flex-direction: column;
    width: 90%;
    /* gap: 20px; */
    margin: 48px 0;
    align-items: center;
    margin: 20px 0 30px;
}

.popup_form > *:not(:first-child) {
    margin-top: 20px;
}

.popup_form input, .popup_form select, .popup_form textarea {
    border: 1px solid #c2c2c2;
    padding: 10px 14px;
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #161828;
    width: 100%;
}

.popup_form select {
    display: inline-block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    background-image: url("/public/img/header/popup_arrow_down.png");
    background-position: 95%;
    background-color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.popup_form select option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.popup_form input:focus {
    border: 1px solid #c2c2c2;
}

.popup_form .input_text {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup_description {
    text-align: center;
    margin: 10px 0;
    width: 43%;
}

.privacy_policy {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #497af9;
}

.popup_form input {
    padding-top: 10px; /* Добавляем отступ сверху */
    font-size: 16px; /* Устанавливаем нужный размер шрифта */
    line-height: 1; /* Убираем лишний межстрочный интервал */
    vertical-align: top; /* Выравниваем курсор ввода по верхнему краю */
    display: block; /* Делаем инпут блочным элементом */
}
  
.popup_form ::placeholder {
    position: absolute; /* Делаем placeholder позиционированным */
    top: 10px; /* Прижимаем его к верхнему краю */
    left: 10px; /* Выравниваем по левому краю */
    transition: all 0.3s ease; /* Добавляем плавный переход при фокусе */
}
  
.popup_form input:focus::placeholder {
    opacity: 0; /* Скрываем placeholder при фокусе на инпуте */
}
  
/*БЛОК С НОВОСТЯМИ*/

.all_news_block {
    background: #f3fbfd;
}

.all_news_block .container {
    padding: 100px 0;
}

.all_news_block-slide {
    display: flex;
    gap: 48px;
    justify-content: space-between;
    margin-bottom: 100px;
}

.all_news_block_date {
    display: flex;
    align-items: center;
    gap: 9px;
}

.all_news_block_date img {
    width: 14px;
    height: 14px;
}

.all_news_block_inform .description {
    margin: 24px 0;
}

.all_news_block_navigation {
    display: flex;
    justify-content: space-between;
}

.prev, .next {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #282e40;
    transition: 0.3s;
}

.prev img, .next img {
    filter: brightness(0%);
    transition: 0.3s;
}

.prev:hover, .next:hover {
    color: #497af9;
}

.prev:hover img, .next:hover img {
    filter: none;
}

/*БЛОК С ОБОРУДОВАНИЕМ*/

.equipment_block .container {
    padding: 100px 0;
    width: 1094px;
}

.equipment_block_title {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-transform: uppercase;
    color: #161828;
    margin-bottom: 28px;
}

.equipment_block_all_cards {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.equipment_photo_inform {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}

.equipment_block_card_description {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #161828;
    margin-bottom: 5px;
}


/*БЛОК С ЛАБОРАТОРИЯМИ*/

.laboratory_block .container {
    padding: 100px 0;
}

.laboratory_inform_photo {
    display: flex;
    gap: 37px;
    align-items: flex-start;
    flex-direction: row;
}

.laboratory_photo {
    position: sticky;
    top: 20px;
    align-items: start;
}

.laboratory_inform > .description {
    margin-bottom: 32px;
    text-align: justify;
}

.drop_down_block {
    transition: .5s;
    margin-top: 1rem;
}

.laboratory_inform_title {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-transform: uppercase;
    color: #161828;
    background: #fff;
    padding: 14px 16px;
    border: 1px solid #282e40;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}

.main_directions {
    background: #002148;
}

.main_directions .subtitle {
    color: #fff;
}

.main_directions img {
    filter: brightness(0) invert(1);
}

.laboratory_inform_title .arrow-toggler {
    width: 20px;
}

.drop_down_block[open] .arrow-toggler {
    transform: rotate(180deg);
}

.laboratory_inform_title.active {
    color: #fff;
    background: #161828;
}

.laboratory_inform_activ_block {
    padding: 40px 20px 30px 50px;
}

.laboratory_inform .description_item {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.laboratory_inform_activ_block_background, .laboratory_inform_activ_block_important {
    background: #f3fbfd;
}

.laboratory_tasks_description {
    line-height: 160%;
    color: #000;
    margin-bottom: 34px;
}

.laboratory_important {
    display: flex;
    align-items: center;
    gap: 12px;
}

.laboratory_inform_activ_block_important .description {
    text-align: justify;
    margin: 34px 0;
}

.laboratory_inform_activ_block_important_subtitle {
    display: flex;
    align-items: center;
    gap: 14px;
}

.laboratory_important_block {
    background: #fff;
    padding: 21px 25px 21px 12px;
}

.laboratory_important_block_title {
    display: flex;
    align-items: center;
    gap: 12px;
}

.laboratory_important_block_title p {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    text-align: justify;
    color: #ff2020;
}

.laboratory_important_block_text {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #282e40;
    margin: 20px 0 0 37px;
}



/*БЛОК С КОНТАКТАМИ*/

.contact_block .container {
    padding: 100px 0;
}

.contact_block .title {
    margin-bottom: 64px;
}

.contact_map_inform {
    display: flex;
    gap: 16px;
}

.contact_inform {
    display: flex;
    gap: 16px;
}

.contact_inform_card {
    width: 321px;
    height: 427px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contact_inform_title {
    color: #fff;
    background: #161828;
    padding: 16px 20px;
}

.contact_inform_card_detail {
    display: flex;
    flex-direction: column;
    border: 1px solid #c2c2c2;
    border-top: 0;
    padding: 24px 12px 12px;
    height: 100%;
    justify-content: space-between;
}

.contact_inform_card_detail_block {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.contact_inform_inscription {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
    color: #c2c2c2;
    margin-bottom: 4px;
}

.button_contact {
    width: 100%;
    border: 1px solid #282e40;
}

/*ОБРАТНАЯ СВЯЗЬ*/

.feedback_block .container {
    padding: 157px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.feedback_block .title {
    color: #161828;
    text-align: center;
    margin-bottom: 40px;
}

.feedback_block .description {
    margin-bottom: 70px;
}

/*БЛОК С ЛИЦЕНЗИЯМИ*/
.licenses_block {
    position: relative;
    overflow-x: hidden;
}

.licenses_block .container {
    padding: 100px 0;
    display: flex;
    gap: 85px;
    position: relative;
}

.licenses_title {
    color: #fff;
    width: 102%;
}

.licenses_block_container {
    overflow-x: clip;
}

.licenses_block_right {
    display: flex;
    gap: 14px;
    transition: .3s;
}

.licenses_block_right img {
    height: 410px;
    max-width: 290px;
}

.licenses_block_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.license-pop_up {
    position: fixed;
    display: none;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    z-index: 99;
}

.license-pop_up.open {
    display: flex;
}

.license-pop_up img {
    position: absolute;
    height: auto;
    max-width: 90vw;
    min-width: 30vw;
    transform: translate(-50%);
    top: 2%;
    left: 50%;
}

.licenses_block .main_button.comp_vers {
    position: absolute;
    top: 45%;
    background: #497af9;
}

.licenses_block .main_button.comp_vers img {
    filter: brightness(0) invert(1);
}

.licenses_block .main_button.comp_vers:hover {
    background: #fff;
}

.licenses_block .main_button.comp_vers:hover img {
    filter: none;
}

.licenses_block .main_button_left.comp_vers {
    left: 30%;
    border: none;
}

.licenses_block .main_button_right.comp_vers {
    right: 0;
}

.licenses_slide_card {
    padding: 18px;
    background: #f3fbfd;
}

.licenses_slide_card_all_text {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 14px;
}

.licenses_slide_card_all_text .subtitle {
    height: 40px;
}

.licenses_slide_card_text {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #161828;
    height: 25px;
}

.mobile_vers {
    display: none;
}

/*ГЛАВНЫЙ БЛОК НА ВСЕХ СТРАНИЦАХ*/

.bread_crumbs {
    display: flex;
    gap: 4px;
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #fff;
    margin-bottom: 24px;
}

.bread_crumbs a {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #fff;
}

/*Карьера*/

.career_menu {
    background: #497af9;
    display: flex;
    justify-content: center;
    gap: 15%;
}

.career_menu_link {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
    color: #fff;
    padding: 23px 0;
}

.career_advantages {
    padding: 100px 0;
}

.career_all_cards {
    display: grid;
    grid-template-areas:
        "n_one n_two n_three"
        "n_four n_four n_three";
    margin-top: 64px;
    gap: 20px;
    grid-template-columns: calc(28% - 40px) 38% 34%;
    grid-template-rows: 430px 430px;
}

.career_card {
    padding: 65px 45px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.n_one {
    grid-area: n_one;
    background-image: url("../../public/img/career_block/fon1.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.n_one .description {
    margin-bottom: 50px;
}

.n_two {
    grid-area: n_two;
    background: #f8f8fa;
}

.n_two .description_one {
    margin-bottom: 24px;
}

.n_three {
    grid-area: n_three;
    background: #f3fbfd;
}

.n_three_img {
    margin-top: 115px;
}

.n_three .career_title {
    margin-bottom: 24px;
}

.n_three .description {
    margin-bottom: 24px;
}

.n_four {
    grid-area: n_four;
    background-image: url("../../public/img/career_block/fon4.png");
}

.n_four p {
    color: #fff;
    width: 72%;
}

.n_four .career_title {
    margin-bottom: 25px;
}

.n_four .description {
    margin-bottom: 25px;
}

.career_title {
    font-family: "FreeSet-Bold", sans-serif;
    font-weight: bold;
    font-size: clamp(24px, 2vw, 42px);
    line-height: 150%;
    text-transform: uppercase;
    color: #282e40;
}

/*Резюме*/

.resume_block .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    gap: 24px;
    padding-bottom: 110px;
}

.resume_block .title {
    text-align: center;
}

/*Фотогалерея*/

.gallery_block {
    background: #f3fbfd;
    padding: 100px 0;
}

.gallery_block_text {
    width: 27%;
    position: absolute;
}

.gallery_block_text .title {
    margin-bottom: 24px;
}

.gallery_slider {
    display: flex;
    overflow: hidden;
    align-items: flex-end;
    gap: 8px;
    position: relative;
}

.gallery_slider_item img {
    width: 212px;
    height: 179px;
    object-fit: cover;
}

.gallery_slider_item.active img {
    width: 741px;
    height: 640px;
}

.gallery_switches {
    position: absolute;
    right: 0;
    bottom: 4px;
}

/*Команда*/

.numbers_company {
    padding: 100px 0 120px;
    display: flex;
    flex-wrap: wrap;
    gap: 100px;
    justify-content: center;
    width: 70%;
    margin: 0 auto;
}

.numbers_company_item {
    width: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.numbers_company_counter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.numbers_company_item_title {
    font-family: "Benguiat Rus", sans-serif;
    font-weight: 400;
    font-size: 70px;
    text-align: center;
    color: #497af9;
}

.numbers_company_counter_sign {
    font-family: "Benguiat Rus", sans-serif;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #497af9;
}

.command_short {
    display: flex;
    gap: 68px;
    padding-bottom: 80px;
}

.command_short_text {
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: justify;
}

/*История*/

.history_block {
    background: #f3fbfd;
}

.history_block .container {
    padding: 100px 0 80px;
}

.history_all_cards {
    position: relative;
}

.history_card {
    display: flex;
    padding: 20px 30px;
    cursor: pointer;
}

.history_card img {
    position: relative;
    z-index: 99999;
}

.history_card::after {
    content: " ";
    position: absolute;
    left: 45px;
    border-left: 3px dashed #c2c2c2;
    height: 98%;
    top: 37px;
    z-index: 0;
}

.history_block_year_text_img {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.history_block_year {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #497af9;
}

.history_card.active_card .history_block_year {
    font-weight: 400;
    color: #fff;
}

.history_block_year_text {
    margin-left: 35px;
}

.history_block_text {
    margin-top: 8px;
}

.history_card.active_card .history_block_text {
    color: #f3fbfd;
    margin-top: 24px;
    width: 95%;
}

.history_block_img {
    display: flex;
    align-items: center;
}

.history_block_img > img {
    display: none;
}

.history_card.active_card .history_block_img > img {
    display: block;
}

.history_block .title {
    margin-bottom: 64px;
}

.active_card {
    /* height: 350px; */
    background: #497af9;
}

/*Руководство*/

.management_block {
    padding: 100px 0 108px;
}

.management_block .title {
    margin-bottom: 64px;
}

.management_all_cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 37px;
}

.management_card {
    cursor: pointer;
}

.management_card .subtitle {
    margin: 24px 0;
    transition: 0.3s;
}

.management_card:hover .subtitle {
    color: #497af9;
}

.management_popup {
    display: flex;
    gap: 55px;
    padding: 50px;
}

.management_popup_inform .subtitle {
    margin: 24px 0;
}

.management_popup_inform .description {
    text-align: justify;
}

/*Миссия компании*/

.mission_block {
    background: #f3fbfd;
    padding: 100px 0;
}

.mission_company {
    border: 1px solid #cedcf9;
    width: 85%;
    margin: 0 auto;
    padding: 10%;
    text-align: center;
}

.mission_title {
    font-family: "FreeSet", sans-serif;
    font-weight: 400;
    font-size: 42px;
    line-height: 140%;
    text-transform: uppercase;
    color: #161828;
    margin: 24px 0;
}

.mission_company .description {
    width: 50%;
    margin:  0 auto;
}

/*Презентация*/

.presentation_block {
    background-image: url("../../public/img/presentation_block/fon.png");
    -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    padding: 90px 0 110px;
}

.presentation_block .description {
    margin: 48px 0;
    color: #fff;
    width: 402px;
}

.presentation_block .title {
    color: #fff;
}

.presentation_block_switches {
    display: flex;
}

.presentation_block_switches .main_button {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px 44px;
}

.presentation_button_left {
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
}

.presentation_button_left:hover {
    border: 1px solid transparent;
    background: #fff;
    color: #161828;
    border-right: 1px solid #497af9;
}

.presentation_button_left:hover img {
    filter: brightness(0) saturate(100%) invert(37%) sepia(72%) saturate(2148%) hue-rotate(212deg) brightness(104%) contrast(95%);
}

.presentation_button_right {
    border: 1px solid transparent;
}

.presentation_button_right:hover {
    color: #fff;
    border: 1px solid #fff;
}

.presentation_all_slides {
    background: #f3fbfd;
    padding: 26px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.presentation_slide img {
    width: 100%;
}

/*Команда - Обратная связь*/

.expertise_block_2 {
    background-image: url("../../public/img/expertise_block/fon2.png");
    padding: 80px 0;
}

.expertise_block_2 .expertise_title {
    color: #161828;
    margin: 48px 0;
}

.expertise_block_2 .expertise_subtitle {
    color: #282e40;
    text-align: center;
}

.expertise_block_2 .text {
    text-align: center;
    color: #0634aa;
}

.expertise_block_2 .blue_button {
    margin: 64px auto 0;
}

.expertise_block_2 .blue_button img {
    filter: brightness(0) invert(1);
}

.expertise_block_2 .blue_button:hover img {
    filter: brightness(0%);
}

.link_certificates {
    background: #497af9;
    padding: 30px 0;
}

.link_certificates .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link_certificates .title {
    color: #fff;
}

/*Страница новостей*/

.news_page {
    background: #f3fbfd;
}

.news_page .container {
    padding: 100px 0;
}

.news_page .title {
    margin-bottom: 64px;
}

.news_page_all {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.news_page .news_slide_img img {
    width: 100%;
    margin-bottom: 20px;
}

.news_more {
    margin: 16px 0 20px;
}

/*Адаптив*/

@media screen and (max-width: 1600px) {
    .gallery_block_text {
        width: 36%;
    }
}

@media screen and (max-width: 1450px) {
    /*АДАПТИВНАЯ ШАПКА*/

    .header_menu {
        display: none;
    }

    .header_menu_adaptiv {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header_adaptiv_burger, .header_adaptiv_search {
        width: 72px;
        height: 72px;
        background: #497af9;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .running_line_2 {
        display: flex;
    }

    .running_line_2 > p {
        padding: 10px 0px;
    }

    header hr {
        display: block;
        height: 0.1px;
        background-color: #c2c2c2;
        border: none;
    }

    .burger-checkbox {
        position: absolute;
        visibility: hidden;
      }
      .burger {
        position: relative;
        z-index: 1;
        cursor: pointer;
        display: block;
        position: relative;
        border: none;
        background: transparent;
        width: 40px;
        height: 20px;
      }
      .burger::before,
      .burger::after {
        content: '';
        left: 0;
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 10px;
        background: #fff;
      }
      .burger::before {
        top: 0;
        box-shadow: 0 9px 0 #fff;
        transition: box-shadow .3s .15s, top .3s .15s, transform .3s;
      }
      .burger::after {
        bottom: 0;
        transition: bottom .3s .15s, transform .3s;
      }
      .burger-checkbox:checked + .burger::before {
        top: 8px;
        transform: rotate(45deg);
        box-shadow: 0 6px 0 rgba(0,0,0,0);
        transition: box-shadow .15s, top .3s, transform .3s .15s;
      }
      .burger-checkbox:checked + .burger::after {
        bottom: 11px;
        transform: rotate(-45deg);
        transition: bottom .3s, transform .3s .15s;
      }

      /*бургер-поиск*/

      .header_menu_adaptiv .search_menu {
        width: 100% !important;
      }

      .header_menu_adaptiv .search_menu .burger_details {
        width: 100%;
      }

      .search_menu .burger_details > summary {
        border-bottom: none;
      }

      .menu {
          left: 0;
          top: 72px;
          width: 100vw;
          /* height: calc(100vh - 60px); */
          position: absolute;
          display: none;
          background: white;
          transition: .3s;
          z-index: 10000;
          max-width: 100%;
      }

      .menu > div {
        width: 100%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .menu_contacts_container {
        margin: 10px auto 20px;
        align-items: start !important;
        width: 90% !important;
      }

      .header_burger_phone {
        font-family: "FreeSet-Bold", sans-serif;
        text-decoration: none;
        font-weight: bold;
        color: #161828;
      }

      .menu_images {
        display: flex;
        margin-top: 13px;
      }

      .menu_images > div:last-child {
        margin-left: 3px;
      }

      .menu_image_container {
        transition: .3s all;
        cursor: pointer;
      }

      .menu_image_container > img {
        width: 28px;
        height: 28px;
      }

      .menu_image_container:hover {
        scale: 1.2;
      }

    .header_adaptiv_burger:has(.burger-checkbox:checked) ~ .menu {
        display: flex;
        flex-direction: column;
        background: #F3FBFD;
    }

    .header_adaptiv_burger .menu a:hover {
        color: #497af9;
    }

    .burger_details {
        transition: .3s;
        width: max(350px, 90%);
    }

    /*.burger_details_inner {*/
    /*    cursor: pointer;*/
    /*}*/

    .burger_details > summary {
        border-bottom: 1px solid #c2c2c2;
        padding: 13.5px 0;
    }

    .burger_details > div {
        /* padding-left: 10px; */
        padding-top: 10px;
    }

    .burger_details > summary a {
        text-decoration: none;
        color: black;
    }

    .burger_details > div > ul > li {
        margin-top: 10px;
    }

    .burger_details > div > ul > li:last-child {
        /* padding: 0 0 20px 0; */
        padding: 0;
    }

    .burger_details_inner > summary::marker {
        display: none;
        content: "";
    }

    .burger_details_inner > summary {
        font-size: 12px;
        cursor: pointer;
    }

    .burger_details_inner[open] > summary {
        background: #497af9;
        padding: 10px;
        color: white;
    }

    .burger_details_inner[open] > div {
        background: #fff;
        padding: 20px;
    }

    .burger_details_inner > div a {
        text-decoration: none;
        color: black;
    }

    .burger_details_inner[open] li {
        margin-top: 8px;
    }

    .burger_details_inner[open] li > a {
        font-size: 12px;
        transition: 0.3s;
        cursor: pointer;
    }

    .burger_details_inner[open] li:hover > a {
        color: #497af9;
    }

    .burger_details > summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .burger_details[open] > summary > img {
        transform: rotate(90deg);
    }

    
    .container {
        width: 1000px;
    }

    .main_description {
        width: 85%;
    }

    .title {
        font-size: 30px;
    }

    .main_title {
        font-size: 24px;
        width: max-content;
    }

    .main_title_news_detail {
        width: min-content;
    }

    .search_description {
        margin: 48px 0 20px;
    }

    .search {
        width: 529px;
    }

    .search_block_inform {
        width: 85%;
    }

    .search_block .container img {
        width: 100%;
    }

    .fourth_title {
        width: 60%;
        margin-bottom: 48px;
    }

    .fourth-inform {
        display: flex;
    }

    .fourth_black_block {
        display: none;
    }

    .fourth_black_block_adaptiv {
        display: flex;
        width: 253px;
    }

    .fourth_black_block_adaptiv .fourth_description {
        color: #fff;
        margin: 0;
    }

    .fourth_big_block_photo img {
        width: 545px;
        height: 591px;
    }

    .fourth_big_block_top {
        align-items: normal;
    }

    .fourth_big_block_inform {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .fourth_big_block_link {
        padding: 38px 44px 30px 6px;
    }

    .news_whole_slider, .news_all_slides, .news_slide {
        gap: 16px;
    }

    .question {
        padding: 30px 16px;
    }

    .main_slide img {
        width: 515px;
    }

    .news_slide_img img {
        width: 198px;
    }

    .main_slide .news_slide_date {
        margin: 20px 0 16px;
    }

    .subtitle {
        font-size: 14px;
    }

    .news_slide_date {
        font-size: 12px;
    }

    .news_more {
        margin: 16px 0 24px;
    }

    .footer_link_comp_vers {
        display: none;
    }

    .footer_link_planshet_vers {
        display: flex;
        margin-top: 27px;
        gap: 16px;
    }

    .footer_link_planshet_vers .subtitle {
        margin: 0;
    }

    /*БЛОК С КОНТАКТАМИ*/

    .contact_map_inform {
        flex-direction: column-reverse;
        gap: 64px;
    }

    .contact_inform_card {
        width: 100%;
    }

    /*БЛОК С ЛАБОРАТОРИЯМИ*/

    .laboratory_photo img {
        width: 283px;
    }

    .laboratory_inform_activ_block {
        padding: 30px 20px;
    }

    /*ПОПАП*/

    .popup_form {
        margin: 20px 0;
        /* gap: 15px; */
    }

    .popup_form > *:not(:first-child) {
        margin-top: 20px;
    }

    .popup_logo {
        padding: 20px 0;
        margin-bottom: 30px;
    }

    .modal-body {
        width: 540px;
    }

    .popup_description {
        width: 100%;
    }

    .popup_form select {
        background: none;
    }

    /*БЛОК С ЛИЦЕНЗИЯМИ*/

    .licenses_block .main_button_left.comp_vers {
        left: 39%;
    }

    /*СТРАНИЦА НОВОСТЕЙ*/

    .career_all_cards {
        grid-template-areas:
        "n_one n_three"
        "n_two n_three"
        "n_four n_four";
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        grid-template-rows: 311px 346px 282px;
    }

    .n_three_img {
        margin-top: 0;
    }

    /*КОМАНДА*/

    .numbers_company {
        width: 100%;
    }

    .command_short {
        gap: 20px;
    }

    .management_all_cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .management_card {
        width: min-content;
    }

    .n_four p {
        width: 100%;
    }

    .career_card {
        padding: 45px;
    }

    .gallery_slider_item:not(.active) {
        display: none !important;
    }

    .gallery_block > .container {
        display: flex;
        flex-direction: column;
    }

    .gallery_block_text {
        position: static;
        width: 100%;
    }

    .gallery_slider {
        justify-content: center;
        margin-top: 30px;
    }

    .gallery_switches {
        right: 50%;
        transform: translate(50%);
    }

    .modal-with-content__container {
        width: 90vw;
    }

    .license-pop_up img {
        top: 10%;
    }
}

@media screen and (max-width: 1200px) {
    .all_news_block_picture img {
        width: 513px;
    }
}

@media screen and (max-width: 1100px) {
    /*БЛОК С ОБОРУДОВАНИЕМ*/

    .equipment_block .container {
        width: 900px;
    }
}

/* @media screen and (max-width: 1000px) */
@media screen and (max-width: 1080px) {
    .container {
        width: 900px;
    }

    /*ШАПКА*/

    .search_top {
        left: -828px;
        width: 900px;
    }

    .title {
        font-size: 26px;
    }

    .search_block {
        position: relative;
    }

    .search_block_inform {
        width: 55%;
    }

    .search_block_picture {
        position: absolute;
        right: 0;
        width: 40%;
    }

    .fourth_big_block_photo img {
        width: 491px;
    }

    .text {
        font-size: 16px;
    }

    .all_news_block-slide {
        gap: 24px;
    }

    .all_news_block_picture img {
        width: 513px;
    }

    .main_slide img {
        width: 416px;
    }

    .news_slide_img img {
        width: 160px;
    }

    .slider_arrow {
        width: 33px;
        margin-left: 14%;
    }

    .main_slide .news_slide_date {
        order: 1;
        margin-bottom: 11px;
    }

    .news_slide .subtitle {
        order: 2;
    }

    .news_more {
        order: 3;
        margin: 11px 0 0;
    }

    /*ПОПАП*/

    .popup_form .input_text {
        height: auto;
    }

    .popup_logo img {
        width: 50%;
    }

    /*БЛОК С ЛИЦЕНЗИЯМИ*/

    .licenses_block .container {
        flex-direction: column;
    }

    .licenses_block .comp_vers {
        display: none;
    }

    .licenses_block .mobile_vers {
        display: flex;
        width: 100%;
        align-items: flex-start;
    }

    .licenses_block_right img {
        height: 420px;
    }

    .licenses_block .licenses_block_left {
        flex-direction: row;
    }

    .licenses_block .main_button_switches {
        margin: 0;
    }

    .licenses_block_container {
        overflow-x: visible;
    }

    /*Страница новостей*/

    .news_page_all {
        display: grid;
        grid-template-columns: 1fr 1fr !important;
        gap: 20px;
    }

    .command_short {
        flex-direction: column-reverse;
    }

    .command_short_img {
        margin: 0 auto;
        gap: 48px;
    }

    .mission_company {
        width: 100%;
    }

    .n_three_img img {
        width: 100%;
    }

    header .container {
        width: 100%;
    }

    .search_top {
        left: auto;
        right: 0;
        width: 100vw;
    }
}

/* @media screen and (max-width: 640px) */
@media screen and (max-width: 924px) {
    .container {
        width: 600px;
    }

    /*ШАПКА*/

    .running_line_2 {
        display: none;
    }

    .main_block {
        height: 600px;
    }

    .main_block_command, .main_block_news, .main_block_career {
        height: 340px;
    }

    .main_block .container {
        padding: 98px 0 0px;
    }

    .main_all_button {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .main_button_switches {
        margin: 0;
    }

    .main_button {
        padding: 12px 21px;
    }

    .search_block {
        display: none;
    }

    .fourth_block {
        background: #f3fbfd;
    }

    .fourth_block .container {
        padding: 80px 0;
    }

    .fourth_title {
        width: 100%;
        margin-bottom: 24px;
    }

    .fourth_big_block_top {
        flex-direction: column;
        position: relative;
    }

    .fourth_big_block_photo img {
        width: 100%;
    }

    .fourth_black_block_adaptiv {
        position: absolute;
        top: 0;
        right: 0;
    }

    .fourth_button {
        margin-bottom: 24px;
    }

    .fourth_big_block_link {
        padding: 30px 6px;
    }

    .expertise_block .text {
        margin-bottom: 24px;
    }

    .all_questions {
        gap: 4px;
    }

    .question {
        padding: 19px 16px;
    }

    .question .text {
        width: 77%;
    }

    .questions_block .button_adaptiv {
        display: flex;
        margin-top: 48px;
    }

    .news_block .container, .questions_block .container {
        padding: 80px 0;
    }

    .news_all_slides, .button_and_vk, .title_and_button .blue_button {
        display: none;
    }

    .main_slide > img {
        width: 100%;
    }

    .title_and_button {
        margin-bottom: 24px;
    }

    .title_and_button .main_button_switches {
        display: flex;
    }

    .main_slide .news_slide_date {
        margin: 11px 0 16px;
    }

    .adaptiv_news_more {
        display: flex !important;
    }

    .adaptiv_news_more img {
        width: auto;
    }

    .news_more {
        margin-top: 17px;
    }

    .main_slide .subtitle {
        width: 90%;
    }

    .adaptiv_news_button {
        display: flex !important;
        width: 100%;
        margin-top: 48px;
    }

    .adaptiv_news_button img {
        width: auto;
    }

    .title {
        font-size: 30px;
    }

    .footer_inform {
        display: flex;
        justify-content: space-between;
    }

    .footer_link_planshet_vers {
        margin-top: 0;
    }

    .footer_contact_comp_vers {
        display: none;
    }

    .footer .container {
        flex-direction: column;
    }

    .footer_contact_planshet_vers {
        display: block;
        text-align: right;
    }

    .footer_link_planshet_vers {
        text-align: right;
    }

    .footer_channels {
        justify-content: flex-end;
    }

    .footer_social_media_link {
        justify-content: flex-end;
    }

    .footer_tg_news {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer_services {
        display: flex;
        justify-content: space-between;
        margin-top: 31px;
    }

    .footer_services_first_block {
        width: 50%;
    }

    /*БЛОК С НОВОСТЯМИ*/

    .all_news_block-slide {
        flex-direction: column;
    }

    .all_news_block_picture img {
        width: 100%;
    }

    .all_news_block_inform .description {
        margin: 24px 0 48px;
    }

    .all_news_block-slide {
        margin-bottom: 48px;
    }

    /*БЛОК С ОБОРУДОВАНИЕМ*/

    .main_block_equipment {
        height: 300px;
    }
    
    .equipment_block .container {
        width: 600px;
    }

    .equipment_photo_inform {
        flex-direction: column;
    }

    .right_equipment_photo_inform {
        flex-direction: column-reverse;
        
    }

    /*БЛОК С КОНТАКТАМИ*/

    .main_block_contact {
        height: 300px;
    }

    .contact_map_inform {
        gap: 48px;
    }


    /*БЛОК С ЛАБОРАТОРИЯМИ*/

    .laboratory_photo img {
        display: none;
    }

    .laboratory_inform_photo {
        display: block;
    }

    .laboratory_inform_activ_block {
        height: 400px;
        overflow-y: scroll;    
    }

    .main_block_service {
        height: 400px;
    }

    .main_block_service .main_title {
        width: 90%;
    }

    /*Страница новостей*/

    .news_page_all {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .career_all_cards {
        display: flex;
        flex-direction: column;
    }

    /*КОМАНДА*/

    .numbers_company {
        gap: 60px;
    }

    .career_menu {
        display: none;
    }

    .command_short_img, .command_short_img img {
        width: 100%;
    }

    .management_all_cards {
        grid-template-columns: 1fr 1fr;
    }

    .resume_block .container {
        width: min-content;
    }

    .n_three_img img {
        width: 50%;
    }

    .gallery_slider_item.active img {
        width: 100%;
        height: auto;
    }

    .gallery_switches {
        right: 0;
        transform: none;
    }

    .management_popup {
        flex-direction: column;
    }

    .management_popup_human {
        margin: 0 auto;
    }

    .management_card .subtitle br {
        display: none;
    }

    .management_card {
        width: 100%;
    }

    .history_block_year_text_img {
        flex-direction: column;
        margin-left: 35px;
        gap: 20px;
    }

    .history_block_year_text {
        margin-left: 0;
    }

    .history_card.active_card .history_block_img > img {
        width: 100%;
    }

    .history_card {
        padding: 20px;
    }

    .history_card::after {
        left: 35px;
        border-left: 2px dashed #c2c2c2;
    }

    .news_details {
        height: 390px;
    }

}

@media screen and (max-width: 640px) {
    .mini_block_card {
        flex-direction: column;
        gap: 5px;
        padding: 8px 20px;
    }
}

/*@media screen and (max-width: 380px)*/
@media screen and (max-width: 624px) {
    .container {
        width: 360px;
    }

    /*ШАПКА*/

    .search_top {
        top: 60px;
    }

    .menu {
        top: 60px;
    }

    .header_adaptiv_burger, .header_adaptiv_search {
        width: 60px;
        height: 60px;
    }

    .header_adaptiv_logo img {
        display: flex;
        width: 90%;
        margin: 0 auto;
    }

    .equipment_block .container {
        width: 360px;
    }
    
    .title {
        font-size: 26px;
    }

    .main_block .container {
        padding: 60px 0 11px;
    }

    .main_description {
        margin: 48px 0 28px;
    }

    .main_all_button {
        display: block;
    }

    .main_button_switches {
        margin-top: 30px;
    }

    .fourth_block .container, .expertise_block .container, .news_block .container, .all_news_block .container {
        padding: 70px 0;
    }

    .fourth_block_computer_photo {
        display: none;
    }

    .fourth_block_mobile_photo {
        display: block;
        width: 360px !important;
        height: 381px !important;
    }

    .fourth_black_block_adaptiv {
        width: 214px;
        height: 186px;
    }

    .fourth_big_block_link {
        padding: 15px 6px;
    }

    .fourth_big_block_link_left img {
        width: 50px;
        height: 34px;
    }

    .expertise_title {
        margin: 30px 0;
    }

    .title_and_button .main_button_switches {
        display: none;
    }

    .footer_link_planshet_vers {
        text-align: left;
    }

    .footer_services {
        flex-direction: column;
    }

    .footer_services_first_block {
        width: 70%;
    }

    .footer_contact_planshet_vers {
        text-align: left;
        margin-top: 31px;
    }

    .footer_channels, .footer_social_media_link {
        justify-content: flex-start;
    }

    .footer_tg_news {
        align-items: center;
    }

    /*БЛОК С КОНТАКТАМИ*/

    .contact_map_inform {
        gap: 24px;
    }

     .contact_inform {
        flex-direction: column;
    }

    .contact_map img {
        height: 294px;
    }

    .contact_inform_card {
        width: 100%;
        height: auto;
    }

    .contact_inform_card {
        height: 427px;
    }

    /*ПОПАП*/

    .modal-body {
        width: 360px;
    }

    .popup_inform .subtitle {
        width: 70%;
        text-align: center;
    }

    /*БЛОК С ЛИЦЕНЗИЯМИ*/

    .main_button_switches.mobile_vers {
        display: none;
    }

    .numbers_company {
        gap: 30px;
    }

    .numbers_company_item {
        width: 165px;
    }

    .numbers_company_item_title {
        font-size: 60px;
    }

    .management_all_cards {
        display: flex;
        flex-direction: column;
    }

    .management_card img, .management_card {
        width: 100%;
    }

    .management_card .subtitle br {
        display: none;
    }

    .presentation_block_switches .main_button {
        padding: 10px;
    }

    .license-pop_up img {
        top: 15%;
        min-width: 100vw;
    }

    .main_block.primary_main_block {
        height: 700px;
    }

    .numbers_company, .equipment_block .container, .laboratory_block .container, .news_page .container, .career_advantages, .contact_block .container {
        padding: 60px 0 80px;
    }

    .news_page .title, .contact_block .title {
        margin-bottom: 40px;
    }

    .career_all_cards {
        margin-top: 40px;
    }

}

@media screen and (max-width: 450px) {
    .presentation_block .description {
        width: auto;
    }
}

@media screen and (max-width: 400px) {
    header .container {
        width: 100%;
    }

    .search_top {
        left: auto;
        right: 0;
        width: 100vw;
    }
}
